@import "@aws-amplify/ui-react/styles.css";

body {
  margin: 0;
  padding: 0;
}

#main-map-container {
  padding: 0;
  margin: 0;
  top: 5%;
  bottom: 30%;
  height: 65%;
  width: 100%;
  position: absolute;
}

#feature-table {
  top: 70%;
  height: 30%;
  overflow: scroll;
  z-index: 10;
  position: absolute;
}

#feature-table-container {
  display: block;
}

#toggle-table-btn {
  box-shadow: none;
}

.amplify-tabs {
  display: none;
}

#navbar {
  overflow: hidden;
  background-color: #242424;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5%;
  z-index: 10;
}

#navbar-left, #navbar-right {
  float: left;
  padding: 5px;
  height: 100%;
}

#navbar-left {
  float: left;
}

#navbar-left img {
  height: 100%;
}

#navbar-right {
  float: right;
}

#navbar-right h1 {
  color: #f2f2f2;
  font-size: 0.8em;
  margin: 0;
}

#greeting-container {
  padding-top: 0.4em;
  height: 100%;
}

#btn-container {
  height: 100%;
}